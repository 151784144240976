@font-face {
    font-family: 'Roboto';
    src: url('../font/Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'), url('../font/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('../font/Roboto-Bold.woff') format('woff'), url('../font/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../font/Roboto-Regular.eot');
    src: local('Roboto'), local('Roboto-Regular'), url('../font/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../font/Roboto-Regular.woff') format('woff'), url('../font/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../font/Roboto-Medium.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'), url('../font/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../font/Roboto-Medium.woff') format('woff'), url('../font/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../font/Roboto-Black.eot');
    src: local('Roboto Black'), local('Roboto-Black'), url('../font/Roboto-Black.eot?#iefix') format('embedded-opentype'), url('../font/Roboto-Black.woff') format('woff'), url('../font/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

/*
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url('../font/roboto-v20-latin-ext-regular.woff2') format('woff2')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'), url('../font/roboto-v20-latin-ext-500.woff2') format('woff2')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'), format('embedded-opentype'), url('../font/roboto-v20-latin-ext-700.woff2') format('woff2')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: local('Roboto Black'), local('Roboto-Black'),  format('embedded-opentype'), url('../font/roboto-v20-latin-ext-900.svg#Roboto') format('svg')
}
*/

/*

@font-face {
    font-family: 'Roboto';
    src: url(../font/Roboto-Regular.ttf) format('truetype');
    src:url(../font/roboto-v20-latin-ext-regular.woff2);
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../font/roboto-v20-latin-ext-regular.woff2);
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../font/roboto-v20-latin-ext-regular.woff2);
    font-style: normal;
    font-weight: normal;
}
    */

html {
    background-color: black;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #919595;
    border-radius: 0;
    border: solid 1px #919595
}

::-webkit-scrollbar-track {
    background: #e4e4e4;
    margin-bottom: 4px;
    border-radius: 11px;
}

.uppercase {
    text-transform: uppercase !important;
}

body {
    font-size: 14px;
    line-height: 1.52857143;
    color: #dcdcdc;
    background: #202123;
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    height: 100%;
    min-height: 100%;
    padding: 0;
    overflow-x: hidden;
}

.white-text {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    margin-left: 2px;
}

.icofont-2x {
    font-size: 1.3em !important;
}

/*modal*/

.w3-container {
    padding: 0;
    position: absolute;
    /*background-color: #e4e4e4;*/
}

.w3-modal {
    display: block;
    z-index: 9999;
    padding-top: 27px;
}

.w3-modal-content {
    max-width: 450px;
    margin: 0 auto;
    border-radius: .25rem;
}

.registration-tab {
    text-align: center;
    /*background-color: #e4e4e4;*/
    padding: 39px 0 6px;
}

.registration-tab li {
    display: inline-block;
    padding-right: 35px;
    padding-left: 35px;
    position: relative;
    pointer-events: none;
}

.registration-tab .active, .registration-tab .visited {
    pointer-events: auto;
}

.registration-tab .active span, .registration-tab .visited span {
    background-color: #3797ef;
    color: #fff;
}

.registration-tab span {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, .4);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: #4e5050;
}

.registration-tab i {
    font-size: 29px;
}

.registration-tab li:after {
    content: '';
    position: absolute;
    left: 96px;
    right: 30px;
    width: 54px;
    top: 52%;
    height: 2px;
    border: solid 1px #dcdcdc;
}

.registration-tab .border-none::after {
    border: none;
}

.w3-button {
    position: absolute;
    right: 11px;
    padding: 4px;
    background-color: transparent;
    color: #a9abab;
    border: solid 1px transparent;
    z-index: 2;
    cursor: pointer;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, -6px);
    top: 6px;
    font-size: 23px
}

.w3-button:hover {
    color: #a9abab !important;
    background-color: transparent!important;
}

.modal-body {
    position: relative;
    padding: 20px 30px;
}

.modal-content {
    /*background-color: #e4e4e4;*/
    border: none;
    background-clip: padding-box;
    outline: 0;
    padding: 0;
    color: #2e302e;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.modal-content input, .modal-content-deposit input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #e1e1e1;
    background-color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size: 14px;
    transition: box-shadow .5s, border-color .25s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding: 12px 2px 11px 11px;
    font-family: 'Roboto', sans-serif;
    color: #232c2c;
    font-weight: 400;
    text-shadow: 0 0 0 rgba(0, 0, 0, .2);
}

.modal-body input:focus {
    outline: none;
}

.buttons {
    margin-bottom: 3px;
    margin: 0;
    position: relative;
}

.center {
    text-align: center;
}

.btn.primary {
    background-color: #3797ef;
    border-color: #3797ef;
    color: #fff;
}

.w60 {
    width: 60%;
}

.margin20 {
    margin-top: 20px;
}

.are-you-register {
    color: #000;
}

.are-you-register span {
    color: #3797ef;
    font-weight: bold;
    cursor: pointer;
}

.w3-modal footer {
    padding-bottom: 30px;
}

/* loading */

.loading {
    position: fixed;
    z-index: 10001;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    /*background-color: rgba(0,0,0,1);*/
    background-color: #202123;
}

.loading .spinner {
    width: 14em;
    height: 14em;
}

.spinner {
    margin-top: 0;
    display: inline-block;
    width: 4em;
    height: 4em;
    font-size: .4rem;
    text-indent: 999em;
    overflow: hidden;
    border: 2px solid #dadada;
    border-top: 2px solid #3797ef;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9999;
}

/*error message*/

.validation-summary-errors.error {
    position: absolute;
    z-index: 8;
    right: 3px;
    width: auto;
    margin-top: 14px;
    height: 32px;
}

.desktop-login-holder .validation-summary-errors.error {
    background-color: #fff;
    color: #1d1d1d;
    padding: 0;
    border-radius: 4px;
}

.validationfail:before {
    display: inline-block;
    font: normal normal normal 15px/1 iconFonts;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 1px);
    position: relative;
    top: 1px;
    font-size: 1em;
    content: "";
    left: -4px;
    font-size: 13.5px;
    color: white;
}

.login-wrapper .validation-summary-errors.error:before {
    content: '';
    position: absolute;
    top: -12px;
    right: 17px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    overflow: hidden;
    border-top: solid 1px;
    height: 12px;
    width: 16px;
    background-color: #fff;
}

.validation-summary-errors.error li {
    margin-bottom: 0;
    font-weight: 500;
    padding: 5px 14px 7px 12px;
    font-size: 12px;
}

.validation-summary-errors.error i {
    font-size: 16px;
    margin-right: 2px;
    color: #3797ef;
}

.collapse input:focus {
    outline: none;
}

.collapse-padding {
    padding-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.tab-pane input {
    outline: none;
}

.form-group {
    position: relative;
    background-color: white;
    max-height: 46px;
    margin-bottom: 18px;
}

.male-female-radio {
    width: 48%;
    display: inline-block;
    padding: 13px 11px 12px 11px;
    text-align: center;
    color: #232c2c;
    cursor: pointer;
}

.male-female-radio {
    width: 50%;
}

.male-female-radio.active {
    background-color: #b0afaf;
    border-color: #b0afaf;
}

.account-forms {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin-left: -5px;
    margin-right: -5px;
}

.grid-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.account-forms>div {
    padding-left: 5px;
    padding-right: 5px;
}

.grid-cell {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.birthday-form select, .address-form select {
    color: #8d8d8d;
    cursor: pointer;
    padding: 12px 2px 11px 11px;
    text-shadow: 0 0 0 rgba(0, 0, 0, .2);
    border: 1px solid #e1e1e1;
    width: 110px;
}

.address-form select {
    width: 170px;
}

.birthday-form select:focus, .address-form:focus {
    outline: none;
}

/*toggle*/

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #3797ef;
}

input:focus+.slider {
    box-shadow: 0 0 1px #3797ef;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/**/

/*login form*/

.modal-title-padding {
    padding: 5px 0 70px
}

.modal-title-padding h1 {
    color: #2e302e;
    font-size: 32px;
    margin-bottom: 3px;
}

.modal-title-desc {
    color: rgba(35, 44, 44, .5);
    font-weight: 400;
    font-size: 14px;
}

.full {
    width: 100%;
}

.login-padding {
    width: 107px;
    margin: 15px auto 20px;
    border-color: rgba(169, 171, 171, .2);
}

.forgot-password {
    padding: 3px 0 35px;
    float: right;
}

.forgot-password p {
    color: rgba(35, 44, 44, .5);
    cursor: pointer!important;
}

.forgot-pass-modal {
    position: absolute;
    z-index: 9999;
    width: 340px;
    margin-top: 15px;
    margin-left: 67px;
}

.forgot-pass-modal i {
    cursor: pointer;
}

.forgot-password-container {
    background-color: #fff;
    border: none;
    background-clip: padding-box;
    outline: 0;
    padding: 0;
    color: #2e302e;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 30px;
    position: relative;
}

.forgot-pass-modal .close {
    position: absolute;
    top: 11px;
    right: 18px;
    color: #bbb;
    border: none;
    background-color: #fff;
    z-index: 9;
    opacity: 1 !important;
}

.forgot-password-container:before {
    position: absolute;
    content: '';
    background-color: #fff;
    top: -12px;
    right: 17px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    overflow: hidden;
    border-top: solid 1px;
    height: 13px;
    width: 17px;
}

/*
.modal-title-padding {
    padding: 5px 0 13px;
}
    */

.forgot-password-container h1 {
    font-size: 15px;
    color: #000;
    font-weight: 700;
    margin-bottom: 18px;
    margin-top: 0;
    text-align: left;
}

.forgot-password-container h4 {
    color: #6a6a6a;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.forgot-password-container input {
    background-color: #d8d8d8;
    border-color: #d8d8d8;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    border: 0;
    height: 40px;
}

.forgot-password-container input:focus {
    outline: none;
}

.forgot-padding {
    padding: 30px 30px 20px;
}

/**/

a {
    text-decoration: none;
    cursor: pointer;
    color: #3797ef;
}

a:hover {
    text-decoration: none;
    color: #3797ef;
}

.wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.header-main {
    /* -webkit-box-shadow: 0 3px 30px 0 rgba(0,0,0,.8);
    box-shadow: 0 3px 30px 0 rgba(0,0,0,.8);*/
    position: relative;
    z-index: 1000;
    background-color: #000;
}

.header-top-links-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    font-size: 11px;
    width: 100%;
    padding-top: 13px !important;
    font-weight: bold;
}

.max-width {
    max-width: 1380px;
    margin: 0 auto;
}

.headerlogo-container {
    -webkit-box-flex: initial;
    -moz-box-flex: initial;
    -webkit-flex: initial;
    -moz-flex: initial;
    -ms-flex: initial;
    flex: initial;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    width: 100%;
    padding: 0 10px 0;
}

.left {
    padding-left: 10px;
}

.right {
    margin-left: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
}

.header-top-links-wrapper .header-top-link {
    margin: 0 0 0 35px;
}

.header-top-links-wrapper a {
    color: #fff;
}

.navbar-default {
    margin: 17px auto 9px;
    position: relative;
    padding-left: 0;
    padding-right: 0;
}

.navbar-header {
    float: none;
    width: auto;
    position: relative;
}

.logo {
    -webkit-box-flex: initial;
    -moz-box-flex: initial;
    -webkit-flex: initial;
    -moz-flex: initial;
    -ms-flex: initial;
    flex: initial;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin: -2px 0 0 0;
    -webkit-box-flex: 0 1 auto;
    -moz-box-flex: 0 1 auto;
    -webkit-flex: 0 1 auto;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}

.navbar-brand {
    margin: 0 auto 0 0;
    height: 45px;
    min-height: 45px;
    max-height: 45px;
    /*width: 200px;*/
    max-width: 200px;
    cursor: pointer;
}

/*
.logo-header{
    min-height:48px;
    width:194px;
    margin-top:-11px;
    margin-left:5px;
}
    */

.logo-header {
    margin-top: -28px;
    margin-left: 5px;
}

.logo-header img {
    width: 150px;
    height: 60px;
}

.img-responsive, .carousel-inner>.item>img, .carousel-inner>.item>a>img {
    display: block;
    max-width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    width: 100%;
}

.header-button-section {
    -webkit-box-flex: initial;
    -moz-box-flex: initial;
    -webkit-flex: initial;
    -moz-flex: initial;
    -ms-flex: initial;
    flex: initial;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    margin-left: auto;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
}

.user-login-button>div, .user-login-button-mobile>div {
    padding-left: 6px;
    vertical-align: top;
}

.user-login-button>div, .user-login-button-mobile>div {
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
}

input {
    width: 65%;
    border-radius: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.login-wrapper input {
    background-color: #1d1e21;
    padding: 9px 0 10px 6px;
    min-width: 200px;
    border: 1px solid #1d1e21;
    /*color: #adadad;*/
    color: white;
    font-size: 12px;
    font-weight: 500;
    margin-left: 3px;
    margin-right: 2px;
    height: 35px;
    outline:none;
}

    .login-wrapper input:focus {
        background-color: #1d1e21;
        border-color: #3797ef;
        /*color: #adadad;*/
        color: white;
    }

.login-wrapper .validation-summary-errors.error {
    position: absolute;
    z-index: 8;
    right: 20px;
    width: auto;
    margin-top: 14px;
}

.login-inline:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #1d1e21 inset !important;
    -webkit-text-fill-color: #adadad !important;
}

.login-inline:-webkit-autofill:hover, .login-inline:-webkit-autofill:focus, .login-inline:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1d1e21 inset !important;
}

.validation-summary-errors.alert {
    margin: .9em 0 0 0;
    padding: 0;
}

.alert {
    width: 100%;
    color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #94a4a6;
    padding: 9px 8px 9px;
    margin-top: .9em;
}

.relative {
    position: relative;
}

.table-cell {
    padding-left: 6px;
}

/**/

.close-icon {
    position: absolute;
    right: 6px;
    top: 11px;
    cursor: pointer;
}

.error {
    border: 1px solid !important;
    border-color: #d96459 !important;
}

/**/

.move-left {
    transform: translateX(150%);
}

.forgot-text-holder {
    position: absolute;
    right: 8px;
    top: 6px;
    -webkit-transition: all .8s ease-in-out;
    -o-transition: all .8s ease-in-out;
    transition: all .8s ease-in-out;
}

.forgot-text-holder span {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.forgot-text-question-mark {
    -webkit-transform: translateX(450%);
    -moz-transform: translateX(450%);
    -ms-transform: translateX(450%);
    -o-transform: translateX(450%);
    transform: translateX(450%);
}

.forgot-text.move-left+.forgot-text-question-mark {
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
    -o-animation-delay: 1.2s;
    -ms-animation-delay: 1.2s;
    animation-delay: 1.2s;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
}

.custom-link.btn {
    padding-left: 13px;
    padding-right: 13px;
}

.btn.login {
    background-color: #3797ef;
    border-color: #3797ef;
    color: #fff;
}

.custom-link.btn {
    font-family: 'Roboto', sans-serif;
    padding: 5px 13px 6px;
    text-transform: inherit;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 2px;
}

.register {
    background-color: #edbe2d;
    border-color: #edbe2d;
    color: #000;
}

.form-group {
    padding-bottom: 18px;
    position: relative;
    *zoom: 1;
}

.field-validation-error {
    display: block;
    position: absolute;
    font-size: 10px;
    color: #d96459;
    font-style: italic;
    white-space: nowrap;
    font-weight: 400;
    letter-spacing: .03em;
}

.language-section-header-holder {
    padding-left: 9px;
}

.style-sub-menu {
    color: #fff;
    line-height: 1;
    position: relative;
}

.language-dropdown {
    position: relative;
}

.language-dropdown div {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.language-dropdown span {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: #ffffff;
}

.language-dropdown .sub-menu-account span {
    font-weight: 500;
}

.language-mobile .dropdown span {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: #ffffff;
}

.dropdown-content {
    border-color: #393a43;
    background-color: #393a43;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .5);
    right: 5px;
    border-radius: 4px;
    top: 39px;
    position: absolute;
    width: 62px;
    z-index: 999;
}

.dropdown-content::before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 10px;
    margin: auto;
    border: 8px solid transparent;
    border-bottom-color: #393a43;
    width: 0;
    height: 0;
}

.drp-mobile {
    /*width: 70px;*/
    left: -12px;
}

.language-item {
    padding: 5px 0 5px 21px;
    display: block;
    overflow: hidden;
    color: white;
    cursor: pointer;
}

.language-item:hover {
    color: white;
    text-decoration: none;
}

.sub-menu-account {
    margin-bottom: 1px;
}

.img-flag {
    width: 26px;
    height: 26px;
    border-radius: 100%;
}

.margin-left {
    margin-left: 5px;
}

.img-flag:hover {
    transform: scale(1.2);
    transition: transform .2s ease 0s;
}

.img-flag-nohover:hover {
    transform: none;
}

/*user logged*/

.desktop-header {
    padding: 0;
    position: relative;
    display: flex;
    width: 100%;
    column-gap: 5px;
}

.desktop-header>span {
    display: table-cell;
    font-size: 12px;
    position: relative;
    vertical-align: middle;
    font-weight: 500;
    padding: 0;
}


.btn-user-logged {
    font-family: 'Roboto', sans-serif;
    color: #fff;
    display: inline-block;
    border: 1px solid #3797ef;
    background-color: transparent;
    border-color: #3797ef;
    cursor: pointer;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
    text-shadow: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
    height: auto;
    padding: 5px 18px 4px;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 14px;
}

.btn.btn-header-deposit {
    background-color: transparent;
    border-color: #3797ef;
    color: #fff;
}

.btn-header-deposit:hover {
    background-color: #3797ef;
    border-color: #3797ef;
    color: #fff;
}

.refresh-balance-span {
    text-align: center;
    width: 49px;
}

.refresh-balance-span i {
padding-top: 5px;
}

.user-logged .username, .user-logged .username a {
    cursor: pointer;
}

.header-user-account {
    line-height: 14px;
    font-weight: bold !important;
    padding-left: 8px;
}

.balance {
    color: #838383;
    font-weight: normal;
    line-height: 14px;
}

.block {
    display: block;
}

.icon-color {
    color: #838383;
}

.icon-red {
    color: #3797ef;
}

.username {
    display: flex !important;
}

.username:hover, .header-user-account:hover {
    color: #3797ef;
}

.icon-size-26 {
    font-size: 26px;
}

.user-container-top-nav {
    background-color: #eee;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    position: absolute;
    max-height: 547px;
    top: 55px;
    right: 20px;
    width: 320px;
    padding: 16px 0 0;
    color: #555;
    font-size: 12px;
    font-weight: 500;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .5);
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
    z-index: 9999;
    display: block;
}

.user-container-top-nav .arrow-up {
    position: fixed;
    margin-left: 166px;
}

.user-container-top-nav .arrow-up:before {
    border-left: solid 9px transparent;
    border-right: solid 9px transparent;
    border-bottom: solid 9px #eee;
    content: '';
    position: absolute;
    top: -25px;
    left: 7px;
}

.user-container-top-nav-buttons li:hover {
    background-color: #e7e7e7;
}

.user-container-top-nav-list {
    margin-bottom: 12px;
}

.user-container-top-nav-balance {
    border-bottom: solid 1px #d9d6d6;
    padding-bottom: 6px;
}

.user-container-top-nav-list .list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin: 0 11px 1px 14px;
    color: #555;
}

.user-container-cash {
    color: #3797ef !important;
    padding-top: 6px;
}

.user-container-top-nav-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: #d9d6d6;
}

.user-container-top-nav-buttons li {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: -webkit-calc(50% - 1px);
    min-width: -moz-calc(50% - 1px);
    min-width: calc(50% - 1px);
    margin: 1px 0 0 0;
    background-color: #fff;
    position: relative;
}

.user-container-top-nav-buttons span {
    color: #555;
    font-size: 12px;
    display: block;
    position: relative;
    text-align: center;
    cursor: pointer;
}

.user-container-top-nav-buttons .icon {
    display: block;
    padding-bottom: 6px;
    color: #404040;
    height: 58px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    font-size: 35px;
}

.user-container-top-nav-buttons li:nth-child(even) {
    margin-left: 1px;
}

.user-container-top-nav-buttons a {
    color: #555;
    font-size: 12px;
    display: block;
    padding: 0 20px 13px;
    position: relative;
    text-align: center;
}

/*section*/

.border-bottom-red {
    border-bottom: solid 4px #3797ef;
}

.border-top-red {
    border-top: solid 4px rgba(29, 29, 28, .6);
}

.border-bottom-10px {
    border-bottom: solid 10px #000;
}

.header-bg {
    background-color: #222224;
}

ul.nav {
    padding: 0;
    /*font-size: 15px;*/
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    display: inline-flex;
    width: 100%;
    margin: -1px 10px 7px;
    margin-bottom: 9px;
}

ul.nav>li {
    display: table-cell;
    text-align: center;
    position: relative;
    height: 100%;
    line-height: 18px;
    vertical-align: middle;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
}

ul.nav>li.active {
    background-color: #000;
}

ul.nav>li>a {
    color: #fff;
    display: table;
    padding: 11px 14px 7px;
    -moz-flex-direction: column;
    flex-direction: column;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    margin: 0;
    height: 100%;
    position: relative;
    text-align: center;
}

ul.nav>li>a {
    display: table;
    /*padding: 11px 10px;*/
}

ul.nav>li.active>a, ul.nav>li.active .icon {
    color: #fff;
}

ul.nav>li:hover>a, ul.nav>li:hover .icon {
    color: #fff;
}

.nav-left {
    margin-left: auto;
    margin-right: 20px;
}

ul.nav .icon {
    color: #bfbfbf;
    font-size: 27px;
    position: relative;
    top: 2px;
    padding-right: 5px;
}

ul.nav .icon {
    display: table-cell;
    vertical-align: middle;
    top: 0;
    font-size: 17px;
}

ul.nav>li.active>a, ul.nav>li.active .icon {
    color: #fff;
}

ul.nav>li:hover {
    background-color: #000;
    transition-duration: 0.2s;
}

.navbar-btn-container {
    display: none;
}

.sidenav {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 53px;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    overflow-y: auto;
}

.sidenav a {
    padding: 11px 10px;
    text-decoration: none;
    font-size: 14px;
    color: white;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
    background-color: #000;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    cursor: pointer;
}

.mobile-menu {
    width: 100%;
    display: table;
    overflow: hidden;
    margin: 0;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .2);
    overflow-x: hidden;
    overflow-y: auto;
}

.mobile-menu li {
    display: block;
    text-align: left;
}

.mobile-menu-nav {
    padding-left: 17px;
    font-weight: normal;
    font-size: 14px;
}

.language-mobile {
    position: absolute;
    top: 15px;
    margin-left: 4px;
}

.language-mobile .dropdown span {
    margin-left: 5px;
}

.mobile-menu li i {
    font-size: 22px;
}

/*content-wrapper*/

.content-wrapper {
    background-color: #202123;
    position: relative;
}

.live-casino {
    position: relative;
}


.no-padding {
    padding: 0;
}

.rc {
    padding: 32px;
}

.game-categories-holder {
    padding: 0 10px 4px;
}

.game-categories {
    bottom: 0;
    z-index: 900;
    width: 100%;
    min-height: 40px;
    position: relative;
    max-height: 40px;
    white-space: nowrap;
    border: none;
    /*padding-top: 6px;*/
    padding-top: 4px;
    padding-left: 2px;
    overflow: visible;
    vertical-align: middle !important;
    display: flex;
    justify-content: flex-start;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    margin: 0;
    font-weight: 600;
    margin-left: -6px;
}

.game-categories-wrapper {
    display: flex;
}

.auto {
    width: auto;
}

.game-category {
    color: #D8D8D8;
    padding: 6px 0;
}

.game-category:hover {
    cursor: pointer;
    text-decoration: underline;
}

.game-categories .active {
    color: #3797ef;
}

.border-bottom-red {
    border-bottom: 2px solid #3797ef;
}

/*underline border effect */


.item-game:not(.border-bottom-red):before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background: #3797ef;
    visibility: hidden;
    border-radius: 5px;
    transform: scaleX(0);
    transition: .25s linear;
}

.item-game.border-bottom-red {
    border: none;
    text-decoration: underline;
}


.pull-right {
    float: right;
}

.action {
    text-align: right;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -ms-flex-positive: 1;
    -ms-flex-negative: 1;
    -ms-flex-preferred-size: auto;
    padding: 0 .3em 0 0;
}

.search {
    width: 100%;
    position: relative;
    display: inline-flex;
    padding: 19px 5px 15px;
}

.casino-games-filters>a, .casino-games-filters>span {
    font-size: .9em;
    padding: 0 .5%;
    font-weight: normal;
}

.icon-search {
    pointer-events: none;
    left: 14px;
    right: auto;
    top: -webkit-calc(50% - 5px);
    top: -moz-calc(50% - 5px);
    top: calc(50% - 5px);
    color: #757575;
    position: absolute;
}

.input-search {
    background-color: rgba(29, 29, 28, .6);
    border: 1px solid #c6c6c6;
    color: #202123;
    padding: 0px 10px 1px 30px;
    height: 30px;
    border-radius: 4px;
    width: 373px;
    text-transform: capitalize;
    font-size: 11px;
}

.input-search:focus {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    background-color: #000;
    border-color: #e1e1e1;
    color: #424242;
    outline: none;
}

.content-padding .game-categories>div>div:not([class*='-menu']) {
    cursor: pointer;
}




/*game-container*/

.content-padding {
    padding-left: 35px;
    padding-right: 35px;
}

.grid {
    right: 0;
    left: -2px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    position: relative;
    display: flex;
}

.padding-game {
    padding: 0 3px;
}

.games {
    position: relative;
    min-height: 300px;
    overflow-y: hidden;
    z-index: 0;
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
}

.casino-box-shadow {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-bottom: 20px;
    z-index: 3;
}

.mw100 {
    max-width: 100%;
}

.game-list {
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: 150px;
}

.w100 {
    width: 100%
}

.grid-wrap-row {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}


.games .image {
    position: relative;
    min-width: 100px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    max-height: 204px;
    height: 100%;
}

.casino .image img {
    width: 100%;
    height: 100%;
}


.btn.load-more-game {
    width: 338px;
    height: 48px;
    border-radius: 4px;
    border: solid 1px #c6c6c6;
    opacity: 0.8;
    font-size: 20px;
    font-weight: 500;
    color: #d8d8d8;
    background-color: transparent;
    margin: 30px auto;
    display: block;
}




/*footer*/

.footers-container-second {
    background-color: #1c1d20;
    -webkit-transition: background-color .5s;
    -o-transition: background-color .5s;
    transition: background-color .5s;
    padding: 35px 0 0 0;
    margin-top: 28px;
}

.footers-container-second .container {
    padding: 0;
}

.container {
    margin: 0 auto;
}

.footer {
    position: relative;
    padding: 30px 0 0px 0;
    color: #8d8d8d;
}

.footer-disclaimer {
    margin-bottom: 35px;
    margin-left: -5px;
}

.age18 {
    color: #8d8d8d;
    background-color: #8d8d8d;
    margin-top: 30px;
    margin-left: 90px;
}

.footers-container-second .footer .footer-row {
    padding: 0;
}

.links {
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.footer-nav {
    border-bottom: solid 1px #3797ef;
}

.footer-nav {
    text-align: left;
    vertical-align: top;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: bold;
}

.footer-nav .mobile-first-row {
    -webkit-box-flex: 0 1 auto;
    -moz-box-flex: 0 1 auto;
    -webkit-flex: 0 1 auto;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}

.mobile-first-row>div {
    width: 30%;
}

.mobile-first-row>div {
    width: auto;
    min-width: 160px;
}

.footer-text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.footer h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    color: #fff;
}

.link-list {
    line-height: 25px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.footer-nav li {
    margin: 6px 0;
    white-space: nowrap;
}

.footer .links a {
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
}

.social-icons {
    display: block;
    position: relative;
    bottom: -78px;
}

.text-left {
    text-align: left;
}

.social-icons ul {
    margin-top: 14px;
}

.social-icons li {
    margin: 0;
    width: 36px;
}

.inline-block {
    display: inline-block;
}

.social-icons .icon {
    color: #8d8d8d;
    font-size: 27px;
}

.logos {
    display: none;
}

.desktop-curacao-license {
    padding-bottom: 10px;
    padding-top: 25px;
}

.footer-disclaimer p {
    font-weight: 400;
    line-height: 1.47;
    font-size: 12px;
    color: #8d8d8d;
}

.eight-plus {
    text-align: right;
}

.footer .eight-plus {
    margin-right: 3px;
}

.license-logo {
    padding-top: 10px;
}

.footer .license-logo {
    margin: 0 0 0 -20px;
}

.only-mobile {
    display: none;
}

/*payment logo*/

.payment-deposit-logos {
    max-width: 1421px;
    display: flex;
    flex-direction: row;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    flex-wrap: inherit;
    overflow: hidden;
    margin: 0 0 0 9px;
}

.sb-footer-logos .provider-logo {
    margin: 25px 0 0;
    position: relative;
    width: auto;
}

.payment-deposit-logos .game-provider-logo .provider-logo {
    margin: 25px 10px 0;
    position: relative;
    width: auto;
    -webkit-transition: opacity .7s ease;
    -o-transition: opacity .7s ease;
    transition: opacity .7s ease;
}

.sb-footer-logos .img {
    max-height: 100px;
    max-width: 150px;
}

.img-color {
    opacity: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
    transition-duration: 0.2s;
}

.img-color:hover {
    opacity: 1 !important;
    transition-duration: 0.2s;
}


    .logo-header img {
       margin-top: 10px;
    }


.desktop {
    display: block;
}

.mobile-header {
    display: none;
}



.mobile-header>span:first-child, .mobile-header .refresh-balance-holder:first-child {
    padding-left: 0;
}

.mobile-header .deposit {
    width: 25%;
    text-align: left;
}

.mobile-header .subnav-username {
    width: 46%;
}



.mobile-header>span, .mobile-header .refresh-balance-holder {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.mobile-header>span a, .mobile-header>span {
    color: #fff;
}

.mobile-header .refresh-balance-holder {
    width: 40%;
    padding: 2px 0 0;
    text-align: right;
}

.mobile-header .balance {
    color: #838383;
    padding-left: 17px;
    font-weight: normal;
    text-align: left;
    line-height: 14px;
}

.red-button {
    background-color: #3797ef;
    border-color: #3797ef;
}

.height-41 {
    height: 41px;
}

/* deposit payment */

.modal-deposit, .error-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-dialog {
    width: 100%;
    max-width: 820px;
    margin: 30px auto;
    min-height: 480px;
    pointer-events: all;
}

.modal-content-deposit {
    background-color: #fff;
    border: none;
    background-clip: padding-box;
    outline: 0;
    padding: 0;
    color: #2e302e;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    min-height: 400px;
}

.modal-header-deposit {
    border-bottom-color: transparent;
    background-color: #44464c;
    padding: 18px 0;
    position: relative;
    border-bottom: solid 1px transparent;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.modal-close, .modal-header-deposit .close {
    position: relative;
    right: 5px;
    padding: 4px;
    background-color: transparent;
    color: #a9abab;
    border: solid 1px transparent;
    z-index: 2;
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
    font: normal normal normal 15px/1 iconFonts !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 1px);
}

.game-modal-header{
    width: 100%;
    text-align: center;
}

.game-modal-header .icons-container {
    position: absolute;
    top: 35%;
    right: 1%;
    display: flex;
    column-gap: 10px;
}

.game-modal-header .icon {
    border: none;
    color: #fff;
    color: #a9abab;
    font-weight: bold;
    background: transparent;
}

.modal-header-deposit .back-arrow {
    display: none;
}

.close:focus {
    outline: none;
}

.modal-title-deposit {
    padding: 0;
    position: relative;
    font-weight: 400;
    margin: 0 36px 0 30px;
    color: #fff;
    text-shadow: none;
    font-size: 22px;
    text-align: center;
}

.modal-body-deposit {
    padding: 0;
    background-color: inherit;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    overflow-x: hidden;
}

.payment-details-section {
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    border-color: #c7c7c7;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.payment-details-section .left-side {
    display: flex;
    flex-direction: column;
    width: 320px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 88vh;
    min-height: 86vh;
}


.paymont-provider-card {
   display: flex;
   padding: 5px 3px 5px 12px;
   background-color: #eeeeee;
   color: #252C2C;
   border-top: 1px solid #cecece;
}

.paymont-provider-active-card {
    background-color: #fff;
    padding: 5px 3px 5px 7px;
    border-left: 5px solid #3797ef;
}


.paymont-provider-card:hover {
    background-color: #f5f5f5;
    padding: 5px 3px 5px 7px;
    border-left: 5px solid #3797ef;
}

.paymont-provider-card .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.paymont-provider-card .image-container img {
    height: 80px;
}

.paymont-provider-card .text-container { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.paymont-provider-card .text-container span { 
    font-size: 12px;
    color: rgba(35, 44, 44, 0.5);
}

.modal-content-deposit .modal-body-deposit .payment-details-section .deposit-checkbox {
    width: max-content;
    display: block;
    transition: none;
    appearance: auto;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    color: #232c2c;
    font-weight: 400;

}

.payment-details-section .left-side .arrow-image { 
    display: none;
}


.payment-details-section .right-side {
    width: calc(100% - 260px);
    padding: 15px 30px 0;
    min-height: 470px;
    border-color: #C7C7C7;
    order: 2;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.right-side .payment-control {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.right-side .balance-summary .account-ballance {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -ms-flex;
        display: flex;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
    }

.right-side .payment-control .amount-input-section input {
    background: #fff !important;
}

.right-side .payment-control .amount-input-section
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
    margin: 0;
}

.right-side .payment-control .amount-input-section  .amount-error{
    font-style: italic;
    color: red;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.1em;
}

.right-side .payment-control .amount-input-section .input-error-state {
    border-color: red !important;
}

.right-side .payment-control .amount-container { 
    width: 100%;
    display: flex;
}

.right-side .payment-control .amount-container .amount-btn { 
    width: calc(25% - 10px);
    font-size: 16px;
    color: #000;
    padding: 9px 18px 9px;
    margin: 7px;
    border-radius: 4px;
    border: 1px solid #3797ef;
    background: #fff;
}

.right-side .payment-control .amount-container .amount-btn:first-child {
    margin-left: 0;
}

.right-side .payment-control .amount-container .amount-btn:last-child {
    margin-right: 0;
}

.right-side .payment-control .amount-container .amount-btn:hover {
    background: #d7e0e6 ;
}

.right-side .payment-control .currency-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.right-side .payment-control .currency-container .currency-btn {
    color: #000;
    padding: 3px 8px ;
    border-radius: 4px;
    border: 1px solid #3797ef;
    background: #fff;
    font-size: 13px;
    line-height: 0.9;
    text-align: left;
}

.right-side .payment-control .make-deposit-btn {
    width: 100%;
    padding: 9px 18px;
    border: 1px solid #3797ef;
    border-radius: 4px;
    background: #3797ef;
    color: #fff;
    font-weight: 500;
}

.right-side .qr-section {
    background-color: rgba(234, 234, 234, 0.8);
    border-radius: 8px;
    padding: 30px 15px 15px 15px;
    row-gap: 40px;
    color: #4e5660;
    font-weight: 500;
}

.right-side .qr-section img {
    width: 150px;
}

.right-side .qr-section .time-info {
    color: #518335;
    font-weight: 400;
    text-align: center;
}

.right-side .withdraw-section {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.qr-section .address-section {
    padding: 11px;
    background: #fff;
    border: solid 1px #c1c1c1;
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.qr-section .address-section i {
    font-size: 18px;
}


.balance-summary .item {
    -webkit-box-flex: 0 1 0;
    -moz-box-flex: 0 1 0;
    -webkit-flex: 0 1 0;
    -moz-flex: 0 1 0;
    -ms-flex: 0 1 0;
    flex: 0 1 0;
    padding: 0 7px;
    white-space: nowrap;
}

.balance-summary .title {
    text-transform: none;
    font-size: 13px;
    color: #7b7b7b;
    padding-bottom: 0;
    margin-bottom: 3px;
    display: block;
    font-weight: 500;
}

.balance-summary .icon {
    color: #7b7b7b;
    font-size: 28px;
}

.balance-summary .money {
    color: #3797ef;
    display: inline-block;
    padding-left: 6px;
    font-size: 18px;
    font-weight: 400;
}

.payment-modal .message {
    margin: 15px 0;
    padding: 0 0 0 0;
    font-size: 14px;
    font-weight: normal;
    color: #555;
}

.tab-content.current {
    display: block;
    height: 90%;
}

.payment-modal .buttons-group {
    margin-left: -5px;
    margin-right: -5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.modal-body .buttons {
    margin-bottom: 3px;
    margin: 0;
    position: relative;
    margin-top: 18px;
}

.deposit-buttons {
    background-color: #fff;
    border-color: #3797ef;
    color: #000;
    font-size: 16px;
    border-width: 1px;
    margin: 5px;
    height: auto;
    width: -webkit-calc(25% - 14px);
    width: -moz-calc(25% - 14px);
    width: calc(25% - 14px);
    flex-grow: 1;
    cursor: pointer !important;
}

.btn.deposit-buttons:hover {
    background-color: #ffaba6;
    border-color: #518335;
    color: #000;
}

.modal-body-deposit .buttons {
    margin-bottom: 3px;
    margin: 0;
    position: relative;
}

.padding-20 {
    padding-left: 20%;
    padding-right: 20%;
}

.modal-content-deposit input:focus {
    outline: none;
}

.btn:focus {
    box-shadow: none;
}

/*error modal*/

.modal-dialog-error {
    max-width: 450px;
}

.modal-header-error {
    background-color: white;
}

.error-modal-title {
    color: black;
}

.modal-header-error .close {
    color: #000000;
    font-weight: bold;
}

.modal-error-body {
    padding: 30px;
}

.modal-confirm-body {
    display: flex;
    column-gap: 10px;
    justify-content: center;
}

.close-error {
    font-size: 6.3em;
    color: #3797ef;
}

.success {
    font-size: 6.3em;
    color: #00FF00;
}

.modal-content-error {
    min-height: 210px;
}

.modal-content-confirm {
    min-height: max-content;
    padding: 20px;

}

.casino-search {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    top: 20px;
    background-color: #fff;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .5);
    overflow: hidden;
    position: absolute;
    z-index: 4;
    top: 52px;
    left: 5px;
}

.casino-search ul {
    margin-bottom: 0;
}

.casino-search li {
    text-align: left;
    padding: 0 0;
}

.casino-search li:hover {
    background-color: #dfdfdf;
}

.casino-search li span {
    font-size: 12px !important;
    padding: 9px 18px;
    display: block;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -.2px;
    border-bottom: solid 1px #eee;
    color: black;
    text-transform: capitalize;
}

.casino-search li:last-child span {
    border-bottom: none;
}

.icon-cancel {
    position: absolute;
    top: calc(50% - 11px/2);
    right: 12px;
    color: #424242;
    font-size: larger;
    cursor: pointer;
}

/*game modal*/

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

.modal-game {
    max-width: 1070px;
    margin: 7% auto;
}

/* iframe */

.iframe {
    padding: 35.25% 0 0 0;
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #000;
}

.iframe-game {
    padding: 57% 0 0 0;
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #fff;
}

.loading-game {
    width: 10em;
    height: 10em;
}

.fullscreen {
    position: relative !important;
    left: 0;
    margin-left: 10px;
}

.modal-header-deposit .dark-modal-button {
    color: black !important;
    font-size: 25px !important;
}

/*transaction */

.user-area-wrapper {
    background-color: #e5e5e5;
}

.profile-layout-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    max-width: 1330px;
    margin: auto;
}

.navigation {
    width: 200px;
    margin-right: 10px;
    background-color: #fff;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
}

.navigation-title {
    background-color: #202020;
    padding: 13px 5px 11px 13px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}

.navigation-list {
    padding-bottom: 5px;
    padding-top: 3px;
}

.navigation-list span {
    color: #2e302e;
    display: inline-block;
    margin: 10px 5px 2px 13px;
    padding-bottom: 7px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.6;
    cursor: pointer;
}

.active-span, .navigation-list span:hover {
    color: #3797ef !important;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 81%, #3797ef 81.1%, #3797ef 87%, rgba(0, 0, 0, 0) 87.1%, rgba(0, 0, 0, 0));
}

.account-content {
    /*min-height: -webkit-calc(100vh - 150px);
    min-height: -moz-calc(100vh - 150px);
    min-height: calc(100vh - 150px);*/
    flex-basis: auto;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    color: #2e302e;
    max-width: 80%;
}

.balance-summary .account-ballance {
    display: flex;
    justify-content: space-between;
  }

.account-content-title-holder {
    padding: 20px 10px 20px;
    border-bottom: solid 1px #e1e1e1;
}

.account-content-title {
    text-align: center;
    letter-spacing: -.1px;
    font-size: 22px;
    margin: 0;
    color: #2e302e;
}

.account-container-padding {
    padding-left: 32px;
    padding-right: 32px;
}

.account-buttons-wrapper {
    margin: 18px -7px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
}

.btn.account-btn-help {
    background-color: transparent;
    border-color: #3797ef;
    color: #3797ef;
    font-size: 16px;
}

.account-buttons-wrapper>button {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 8px;
}

.btn.account-btn-help:hover {
    background-color: #3797ef;
    border-color: #3797ef;
    color: #fff;
}

.account-content .balance-summary {
    padding: 13px 32px 21px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
}

.balance-summary {
    position: relative;
}

.user-area-container {
    padding: 22px 39px 22px 39px;
    background-color: #f6f6f6;
    border-top: solid 1px #e1e1e1;
}

.cards {
    margin-left: -6px;
    margin-right: -6px;
}

.card .dropdown:focus {
    outline: none;
}

.cards .card {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    margin: 0 5px;
    position: relative;
    color: #fff;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    margin-left: -6px;
    margin-right: -6px;
}

.card select {
    color: #232C2C;
    cursor: pointer;
    padding: 12px 2px 11px 11px;
    text-shadow: 0 0 0 rgba(0, 0, 0, .2);
    border: 1px solid #e1e1e1;
    width: 100%;
}

/*
.select2-results__option {
    background-color: #eee;
    color: #252c2c;
    padding: 2px 11px 13px;
}
    */

.header-main>.content-wrapper>.user-area-wrapper {
    box-shadow: none;
}

.user-info-list-area {
    min-width: 340px;
    max-width: 100%;
    width: 100%;
    min-height: 650px;
}

.withdraw-list {
    padding: 20px 0;
}

.td-pending {
     color: #FFF;
}

.cancel-btn {
    border: 1px solid;
    background: transparent;
    color: red;
}

.cancel-btn:hover {
        color: #000;
} 
    


.no-data-found-title {
    width: 100%;
    color: #8d8d8d;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    padding: 15px 50px;
    transform: translateY(50%);
}

.no-data-found-title .icon {
    font-size: 116px;
    color: #bcbcbc;
    display: block;
    padding-bottom: 17px;
}

/*transaction-mobile*/


/*footer-carousel*/

.footer-carousel-bar {
    background-color: #090909;
}

.owl-carousel-footer .provider-logo {
    margin: 5px 0;
    min-width: 150px;
}

.game-provider-logo {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    flex-wrap: inherit;
    overflow: hidden;
    margin: 0 0 20px;
    height: 70px;
    margin-bottom: 0;
}

.game-provider-logo .owl-item {
    height: 70px;
}

.btn-font14 {
    font-size: 14px;
}

.input-info {
    position: relative;
    right: 0;
    left: 100%;
    z-index: 4;
    margin-top: -23px;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
}

.input-info .arrow {
    z-index: 1001;
    position: relative;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid transparent;
}

.input-info .arrow:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 0;
    display: block;
    border-bottom: 11px solid transparent;
    border-right: 11px solid #f1f1f1;
    border-top: 11px solid transparent;
    z-index: 1;
}

.input-info .info-container {
    padding: .6em;
    border: 1px solid #c8c8c8;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #f1f1f1;
    min-width: 251px;
    max-width: 345px;
    top: -2.5em;
    left: .8em;
    z-index: 1000;
    position: absolute;
}

.input-info .info-container .info {
    display: block;
    background-color: #fff;
    padding: .5em 0 .5em .5em;
}

.input-info .info-container .info span.title {
    color: #2e302e;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
}

.input-info .info-container .info span {
    display: table;
    color: #2e302e;
    margin: 4px;
    font-size: 12px;
}

.validation-error {
    color: #3797ef;
    font-size: 15px;
}

.valid {
    color: #518335;
}



/*promotion*/

.img-fluid {
    max-width: 100%;
    height: auto;
}

.border-promotion {
    max-width: 345px;
    background-color: rgb(15, 20, 30);
    border: 2px solid #3797ef !important;
    border-radius: 4px !important;
}

.grid-center {
    margin: 0 auto;
}

.promoimage {
    max-width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    width: 100%;
    
}


/*my account */

.account-details {
    border-top: solid 1px #e1e1e1;
}

.account-title {
    padding: 15px 39px 15px 39px;
    position: relative;
}

.account-details h4 {
    font-size: 16px;
    letter-spacing: -.1px;
    color: #212c2c;
    font-weight: 600;
    padding: 0;
    position: relative;
}

.accordion-toggle {
    cursor: pointer;
}

.accordion-toggle.active h4, .accordion-toggle.active .icon {
    color: #3797ef;
}

.accordion-toggle.active .accordion-toggle-icon {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.accordion-toggle .accordion-toggle-icon {
    position: absolute;
    right: 10px;
    top: -webkit-calc(50% - 9px);
    top: -moz-calc(50% - 9px);
    top: calc(50% - 9px);
    -webkit-transition: all .2s cubic-bezier(.58, .21, .34, .68);
    -o-transition: all .2s cubic-bezier(.58, .21, .34, .68);
    transition: all .2s cubic-bezier(.58, .21, .34, .68);
}

.accordion-toggle .icon {
    color: #919595;
    padding-left: 7px;
}

.accordion-toggle.active+.accordion-content {
    max-height: 100%;
    box-sizing: border-box;
    overflow: visible;
    opacity: 1;
}

.account-content-inside {
    padding: 22px 39px 22px 39px;
    background-color: #f6f6f6;
}

.accordion-content, .show-in {
    max-height: 0;
    box-sizing: border-box;
    overflow: hidden;
    transition: all .3s ease-in-out;
    opacity: 0;
}

.accordion-content.collapsed, .show-in.collapsed {
    padding: 0 39px;
}

.edit-account-wrapper {
    max-width: 360px;
    margin: 0 auto;
}

.account-details .message {
    color: #555;
    padding-bottom: 11px;
}

.accordion-content input, .input-account input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #e1e1e1;
    background-color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size: 14px;
    transition: box-shadow .5s, border-color .25s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding: 12px 2px 11px 11px;
    font-family: 'Roboto', sans-serif;
    color: #232c2c;
    font-weight: 400;
    text-shadow: 0 0 0 rgba(0, 0, 0, .2);
}

.accordion-content input:focus {
    outline: none;
}

.cards.form-margin {
    margin-left: -10px;
    margin-right: -10px;
}

.account-details label {
    color: #bdbdbd;
}

[type='text'].disabled, [type='password'].disabled, [type='date'].disabled, [type='datetime'].disabled, [type='datetime-local'].disabled, [type='month'].disabled, [type='week'].disabled, [type='email'].disabled, [type='number'].disabled, [type='search'].disabled, [type='tel'].disabled, [type='time'].disabled, [type='url'].disabled, [type='color'].disabled, textarea.disabled, select.disabled, [type='text']:disabled, [type='password']:disabled, [type='date']:disabled, [type='datetime']:disabled, [type='datetime-local']:disabled, [type='month']:disabled, [type='week']:disabled, [type='email']:disabled, [type='number']:disabled, [type='search']:disabled, [type='tel']:disabled, [type='time']:disabled, [type='url']:disabled, [type='color']:disabled, textarea:disabled, select:disabled {
    background-color: #f6f6f6;
    border-color: #d2d2d2;
    color: #a0a0a0;
    opacity: .7;
    filter: alpha(opacity=70);
}

.active-document {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  margin-bottom: 40px;
  padding: 15px 10px;
  border: 2px solid transparent;
}

.rejected-document {
  border-color: red;
}

.pending-document {
  border-color: #fdcb33;
}

.accepted-document {
  border-color: rgb(98, 151, 58);
}

.active-document .name {
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.active-document .date {
  width: 100%;
}

.active-document .date .remove-btn{
    color: red;
    cursor: pointer;
    width: max-content;
}
.active-document .status {
  text-transform: capitalize;
}

.rejected {
  color: red;
}

.pending {
  color: #fdcb33;
}

.accepted {
  color: rgb(98, 151, 58);
}

.claimed {
     color: #3797ef
}

.claim-btn {
    color: #3797ef;
    border: 1px solid #3797ef;
}

.bonus-btn-container {
    display:  flex;
    column-gap: 10px;
}

.max-height-none {
    max-height: none;
}

.select-withdraw {
    background-color: transparent;
}

/*security*/

.text-heading {
    font-size: 16px;
    color: #2e302e;
}

.light-submessages {
    font-size: 14px;
    color: #2e302e;
}

.user-area-container-security {
    padding: 22px 39px 22px 39px;
    background-color: #f6f6f6;
    border-top: solid 1px #e1e1e1;
    height: 265px;
}

.account-content-center .icon {
    font-size: 116px;
    color: #bcbcbc;
    display: block;
    padding-bottom: 17px;
}

.account-content-center {
    text-align: center;
    width: 63%;
    margin: 0 auto;
}

.account-content-border-top {
    border-top: 1px solid rgb(225, 225, 225);
}

.account-content-turnon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    border-bottom: solid 1px #e1e1e1;
    padding: 1em 2em;
}

.account-bold {
    font-size: 16px;
    letter-spacing: -.1px;
    color: #212c2c;
    font-weight: bold;
}

.btn.turn-on {
    font-weight: bold;
    text-transform: uppercase;
    background-color: #fff;
    border-color: #3797ef;
    color: #3797ef;
    font-size: 14px;
}



/*messages*/

.message-folders {
    flex-direction: row;
}

.account-messaging .tab-nav {
    -webkit-box-flex: 0 0 20%;
    -moz-box-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    background-color: #f1f1f1;
    min-height: 587px;
}

.button-row {
    padding: 12px 18px 10px 14px;
}

.account-messaging .message-icon {
    position: relative;
    display: inline-block;
}

.account-messaging .tab-nav ul {
    border-top: 1px solid #d2d2d2;
}

.account-messaging .tab-nav ul li {
    border-bottom: 1px solid #d2d2d2;
    line-height: 40px;
    font-weight: 600;
    border-left: solid 5px transparent;
}

.account-messaging .tab-nav ul li.active {
    background: #fff;
    border-left-color: #3797ef;
}

.account-messaging .tab-nav ul li a {
    display: block;
    padding: 2px 18px 0 33px;
    color: #4e5050;
}

.account-messaging .no-messages-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.account-messaging .tab-content {
    -webkit-box-flex: 0 0 80%;
    -moz-box-flex: 0 0 80%;
    -webkit-flex: 0 0 80%;
    -moz-flex: 0 0 80%;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    min-width: 80%;
    background-color: #fff;
    position: relative;
    padding-right: 1.9em;
    padding-top: 1em;
}

.only-mobile {
    display: none !important;
}

.account-messaging .tab-nav ul li a .unread-count {
    float: right;
}

.account-messaging .unread-count {
    right: -16px;
    top: -3px;
}

.only-desktop {
    display: block;
}

.account-messaging .compose-message {
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 1 0%;
}

.account-messaging .form-groups {
    width: 100%;
}

.account-messaging .form-group {
    padding-bottom: 18px;
    position: relative;
    width: 100%;
}

.messages-form select, .messages-form input {
    color: #8d8d8d;
    cursor: pointer;
    padding: 12px 2px 11px 11px;
    text-shadow: 0 0 0 rgba(0, 0, 0, .2);
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    width: 100%;
}

.messages-form select:focus, .messages-form input:focus {
    outline: none;
}

.message-body .form-control:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
}

.message-submit {
    margin-top: 90px;
}

.btn-medium {
    font-weight: 600;
    width: 140px;
    height: 41px;
}


.validation-summary-errors {
    margin: .9em 0 0 0;
    padding: 0;
    width: 100%;
    color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 9px 8px 9px;
    margin-top: .9em;
    background-color: #d96459;
    font-style: italic;
}

.validation-summary-success {
    margin: .9em 0 0 0;
    padding: 0;
    width: 100%;
    color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 9px 8px 9px;
    margin-top: .9em;
    background-color: #55dd66;
    font-style: italic;
}

/*promotion*/

.img-fluid {
    max-width: 100%;
    height: auto;
}

.promotion ol, .promotion ul {
    list-style: revert;
}

.promotion-box {
    background: #202124;
    margin-bottom: 10px;
}

.promotion-content-wrapper {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr auto;
    -webkit-box-flex: 1;
    flex: 1 1 0%;
}

.pomotion-content-text {
    padding-top: 6px;
}

.promotion-box .title, .promotion-box .text {
    color: #e3e3e3;
    text-transform: uppercase;
    white-space: pre-wrap;
}

.promotion-box .title {
    font-size: 18px;
    text-shadow: none;
    text-transform: initial;
    font-weight: bold;
    margin: 0;
    color: #fff;
}

.promotion-box .title, .promotion-box .text {
    text-overflow: ellipsis;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
}

.promotion-box .buttons-container {
    margin: 0;
    display: -ms-grid;
    display: grid;
    align-items: center;
}

.promotion-box .buttons button {
    display: block;
    height: 37px;
    padding-top: 7px;
    margin: .4em .2em;
    display: inline-block;
    text-transform: uppercase;
}

.btn.terms {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
}

.btn.terms:hover {
    background-color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
    color: #3797ef;
}

.padding-right0 {
    padding-right: 0;
}

/*promotional modal*/

.modal-xl {
    max-width: 770px;
}

.modal-scroll {
    overflow-y: scroll !important;
}

.image-cover {
    object-fit: cover;
}

.close-modal-xl {
    position: absolute;
    z-index: 9999;
    color: white;
    right: 0;
    margin-right: 20px;
    margin-top: 10px;
    opacity: 1;
}

.modal-body-xl {
    padding: 17px 35px;
    color: #252c2c;
}

.modal-xl .title {
    font-size: 40px;
    font-weight: bold;
    text-shadow: none;
    text-transform: initial;
    color: inherit;
}

.modal-xl .text {
    font-size: 23px;
    font-weight: 500;
    color: rgb(96, 96, 96);
}

.terms-condition-modal {
    position: relative;
    font-size: 14px;
    margin-bottom: 30px;
}

.terms-condition-modal p {
    font-size: 9.5pt;
}

.padding-left-modal {
    padding-left: 40px;
}

.padding-left-modal-80 {
    padding-left: 80px;
}


/*search mobile*/

.mobile-search {
    display: none;
}

.mobile-search .icon {
    top: 0;
    left: auto;
    right: 0;
    position: absolute;
    font-size: 15px;
    pointer-events: all;
    cursor: pointer;
    padding: 21px 10px 0 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0, #000 50%);
}

.mobile-search .fa-times-circle {
    opacity: 0.8;
}

.input-search-mobile {
    display: none;
}



.curacao-logo-area {
    display: block;
    position: relative;
    overflow: hidden;
    max-width: 93px;
    min-width: 32px;
    float: right;
    margin: 0 10px 0 0;
}

.eight-plus {
    text-align: right;
    margin-right: -10px;
}

.icon-181 {
    display: inline-block;
}

.icon-181 img {
    max-width: 55px;
    margin-top: -5px;
}



.provider-select .clear-indicator {
    text-align: right;
    position: absolute;
    top: 0;
    width: 100%;
    padding-right: 12px;
}

.provider-select .clear-indicator button {
    font-size: 10px;
    font-weight: 400;
    text-decoration: underline;
    background: none;
    border: none;
    margin-top: 5px;
    cursor: pointer;
    color: #fff;
    z-index: 999;
}

/*Betting Rules*/

.static-pages-content-wrapper .nav {
    padding: 7px 85px 0 0;
}

.static-pages-content-wrapper .nav {
    margin: 0;
    border-bottom: solid 4px #202122;
    width: 100%;
    text-transform: capitalize;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 22px;
    border-top: none;
}

.static-pages-content-wrapper .nav>li {
    display: inline-block;
    font-weight: 500;
    border-bottom: none;
}

.static-pages-content-wrapper .nav>li>a:first-of-type {
    margin: 0 15px;
}

.static-pages-content-wrapper .nav>li>a {
    color: #fff;
    padding: 30px 0 16px;
}

.static-pages-content-wrapper .nav li a {
    font-size: 20px;
}

.static-pages-content-wrapper ul.nav>li:hover {
    background-color: transparent;
    color: 3797ef;
}

.static-pages-content-wrapper ul.nav>li>a:hover {
    color: #3797ef;
}

.static-pages-content-wrapper ul.nav>li.active {
    background-color: transparent;
    color: #3797ef;
}

.static-pages-content-wrapper ul.nav>li>a.active {
    color: #3797ef;
}

.static-pages-content-wrapper .static-pages-content-padding {
    padding: 30px 0 80px 19px;
    margin: 0 10px;
    font-size: 16px;
}

.static-pages-content-wrapper .tab-content>.tab-pane {
    display: block;
}

.accordion {
    color: #dcdcdc;
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
    transition: 0.4s;
}

.active, .accordion:hover {
    background-color: transparent;
}

.panel {
    padding: 15px 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.panel p {
    margin-top: 15px;
    color: white;
}

.border-top-red-1px {
    border-top: solid 1px #3797ef;
}

.platform-help {
    text-align: center;
    margin-top: 20px;
}

.platform-help h1 {
    font-size: 54px;
    padding-top: 50px;
    color: white;
    font-weight: bold;
}

.platform-help .search {
    position: relative;
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 64px;
    width: 100%;
    max-width: 690px;
}

.icon-search-platform-help {
    color: #8d8d8d;
    position: absolute;
    z-index: 2;
    left: 23px;
    top: calc(50% - 8px);
    font-size: 20px;
    height: -webkit-calc(50% - 11px);
}

.help-search {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    height: 63px;
    width: 100%;
    max-width: 680px;
    padding-left: 45px;
}

.help-search:focus {
    outline: none;
}

.help-nav.main {
    padding-bottom: 50px;
    padding-top: 15px;
    text-align: center;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.help-nav.main li {
    font-size: 14px;
    text-align: center;
    margin: 0 2%;
    width: auto;
}

.help-nav.main button {
    background-color: transparent;
    border: 1px solid;
    border-color: white;
    border-radius: 100%;
    height: 58px;
    width: 58px;
    margin-bottom: 12px;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    transition-duration: 0.7s;
}

.help-icon {
    font-size: 26px;
    color: #fff;
}

.help-nav.main button img {
    width: 100%;
    max-width: 40px;
}

.help-nav.main button:hover {
    transform: scale(1.1);
}

.static-pages-content-padding p {
    font-size: 14px;
}

.termsAndCondition {
    padding-bottom: 93px;
}

.termsAndCondition h2 {
    color: #3797ef;
    font-size: 14px;
    margin-bottom: 17px;
}

.termsAndCondition a {
    color: #dcdcdc;
    cursor: pointer;
}

hr {
    border-top: 1px solid #eee;
}




.devices-media-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}

.devices-media-wrap ul {
    margin-top: 14px;
}

.support-devices-wrap {
    width: 50%;
}

.link-list {
    line-height: 25px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.link-list span {
    background-image: url(../img/devices.svg);
}

.devices {
    width: 156px;
    height: 55px!important;
    background-repeat: no-repeat;
    background-size: contain;
}

.logos.flex {
    margin: 0 0 0 40px;
    height: 100%;
}

.btn-link:hover {
    color: #3797ef;
    text-decoration: none;
}

/*About us*/

.static-pages-content-wrapper .static-pages-content-padding {
    padding: 30px 0 80px 19px;
    margin: 0 10px;
}

.static-pages-content-padding h2 {
    font-size: 22px;
    margin-bottom: 17px;
}

.static-pages-content-padding p {
    font-size: 16px;
}

.faq:focus {
    text-decoration: none;
}

.faq:hover {
    color: #dcdcdc;
    text-decoration: none;
}

/*contact us */

.contact-us-details>div {
    padding: 50px 20px 50px 0;
}


.icon-contact {
    font-size: 60px;
    vertical-align: middle;
    padding-right: 20px;
}

.contact-us-details span {
    display: table-cell;
    font-size: 14px;
}

.contact-us-title {
    margin-top: 30px;
    margin-bottom: 20px;
}

.contact-us-details .form-control {
    height: 50px;
}

.contact-us-details.btn.primary {
    background-color: #3797ef;
    border-color: #3797ef;
    color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
    background-color: #3797ef;
    border-color: #3797ef;
    color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.contact-us-details.btn.full {
    width: 100%;
    text-align: center;
}



.contact-us-details .form-control {
    margin-bottom: 18px;
}

.scroll-top {
    position: fixed;
    z-index: 99999;
    bottom: 80px;
    right: 40px;
    padding: 0 !important;
    cursor: pointer;
    overflow: hidden;
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
}

.scroll-top i {
    font-size: 40px;
}

.scroll-top--visible {
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
}

.game_search_text_input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #1d1e21 inset !important;
    -webkit-text-fill-color: #adadad !important;
}

.game_search_text_input:-webkit-autofill:hover, .game_search_text_input:-webkit-autofill:focus, .game_search_text_input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1d1e21 inset !important;
}


.game_search_text_input {
    border: 1px solid #c6c6c6 !important;
    background-color: transparent !important;
    border-radius: 4px !important;
    width: 373px;
    height: 30px;
    font-size: 11px !important;
    padding-left: 9px !important;
    color: #c6c6c6 !important;
    box-shadow: none !important;
}

.game_search_text_input:focus, .game_search_text_input:active {
    background-color: #000
}

html[dir=rtl] .game_search_text_input {
    padding-right: 35px
}

/*
.game, .center-area-game {
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
}
    */

.GameList-noGames {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: #a3a3a3;
    padding: 150px;
}

.spinner-loading {
    margin: 75px auto;
    padding: 35px;
}

@font-face {
    font-family: "iconFonts";
    src: url("../font/icomoon.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

.select2-container {
    font-size: 14px;
}

select+.select2-container {
    max-width: 100%;
    min-width: 100%;
}

.select2-results__option {
    padding: 12px 2px 11px 13px;
}

.select2-container--default {}

.select2-container--default .select2-results>.select2-results__options {
    overflow-y: auto;
    max-height: 290px;
}

.select2-container--default .select2-selection--single {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #e1e1e1;
    background-color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size: 14px;
    transition: box-shadow .5s, border-color .25s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding: 12px 2px 11px 11px;
    font-family: 'Roboto', sans-serif;
    color: #232c2c;
    font-weight: 400;
    text-shadow: 0 0 0 rgba(0, 0, 0, .2);
    padding: 6px 6px 34px 6px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: inherit;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100%;
    color: #9c9b9b;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: transparent !important;
    border-width: 0;
    height: auto;
    margin-left: 0;
    margin-top: 0;
    right: 7px;
    left: auto;
    top: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:after {
    content: "";
    display: block;
    position: absolute !important;
    top: -2px;
    display: inline-block;
    font: normal normal normal 15px/1 iconFonts !important;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 1px);
    right: 0;
    font-size: 13px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #8d8d8d;
}

.select2-container--default .select2-container--disabled {
    background: #f6f6f6;
    color: #a0a0a0;
    border-color: #d2d2d2;
}

.select2-container--default .select2-container--disabled.select2-selection--single {
    background: #f6f6f6;
    color: #a0a0a0;
    border-color: #d2d2d2;
}

.select2-container--default.select2-container--open .select2-selection--single {
    background-color: #fff;
    cursor: text;
    color: #2e302e;
    border-color: #e1e1e1;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent !important;
    border-width: 0;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b:after {
    content: "";
    cursor: pointer;
}

.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #eee;
    color: #252c2c;
}

.select2-container--default .language-dropdown .select2-results__option[aria-selected=true] {
    background-color: #393a43;
    color: #fff;
}

.select2-container--default .language-dropdown .select2-results__option--highlighted[aria-selected] {
    background-color: transparent !important;
    color: #fff;
}

.select2-container--default .language-dropdown .select2-results>.select2-results__options {
    overflow: hidden;
    width: 100%;
    max-height: 100%;
    margin-top: 0;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: .4em;
}

.select2-dropdown {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #fff;
    color: #2e302e;
    border: solid 1px #e1e1e1;
    z-index: 2099;
}


.clearable {
    position: relative;
    display: inline-block;
}

.clearable input[type=text] {
    padding-right: 24px;
    width: 100%;
    box-sizing: border-box;
}

.clearable__clear {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 8px;
    font-style: normal;
    font-size: 1.4em;
    user-select: none;
    cursor: pointer;
}

.clearable input::-ms-clear {
    /* Remove IE default X */
    display: none;
}

.area-icon-label {
    display: -webkit-inline-box;
}

.searchIconShow {
    background: url(../img/zoom-out.svg) no-repeat !important;
    background-position: calc(100% - 10px) center !important;
}

.icon-submenu {
    float: left;
    text-align: center;
    width: 43px;
    font-size: 26px;
}

.primarycheck {
    font-size: 6.3em;
    color: #3797ef;
}

.input-validation-error {
    border-color: #D96467 !important;
    outline-color: #D96467 !important;
    outline-offset: 0;
}

.input-validation-error:focus {
    border-color: #D96467 !important;
    outline-color: #D96467 !important;
    outline-offset: 0;
}

.input-validation-error:focus-visible {
    border-color: #D96467 !important;
    outline-color: #D96467 !important;
    outline-offset: 0;
}

input.input-validation-error:focus {
    outline: none;
    border: 1px solid #D96467;
    -webkit-box-shadow: 0px 0px 5px #D96467;
    box-shadow: 0px 0px 5px #D96467;
}

.formvalid:focus {
    outline: none;
    border: 1px solid #3797ef;
    -webkit-box-shadow: 0px 0px 5px #3797ef;
    box-shadow: 0px 0px 5px #3797ef;
}

/*
.formvalid {
    border: 1px solid !important;
    border-color: #3797ef !important;
}
    */

.select-validation-error {
    font-size: 10px;
    color: #d96459;
    font-style: italic;
    white-space: nowrap;
    font-weight: 400;
    letter-spacing: .03em;
}

.margin10 {
    margin-top: 10px !important;
}

.positionrelative {
    position: relative;
}

.paddingbottom1 {
    padding-bottom: 1px !important;
}

.paddingleft11 {
    padding-left: 11px;
}

.modal-header {
    padding: 18px 0;
    position: relative;
    background-color: #fff;
    border-bottom: solid 1px transparent;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0
}

.borderwhite {
    border-color: #fff !important;
}

.react-bs-table  {
    min-width: 800px;
}

@media (min-width: 1421px) {
    .container {
        max-width: 1411px;
    }
}

@media screen and (max-width: 1200px) {
    .contact-us-details>div {
        padding: 50px 20px 0px 0;
    }
    .margin30-contact {
        margin-top: 30px;
    }
    .content-wrapper .static-pages-content-padding {
        padding: 10px 20px 20px;
        margin: 0;
    }

    .react-bs-table-container {
        overflow-y: auto;
    }

    .react-bs-table-container::-webkit-scrollbar-thumb {
        display: none;
    }

    .react-bs-table-container::-webkit-scrollbar-track {
        display: none;
    } 


}

@media screen and (max-width: 1024px) {
    .no-mobile {
        display: none !important;
    }
    .mobile-login-holder {
        display: table-cell;
        padding-left: 6px;
        vertical-align: top;
    }
    .logo-header {
        padding: 7px 0 7px 8px;
    }
    .logo-header img {
        height: auto;
        width: 115px;
        margin-top: 10px;
    }
    .user-container-top-nav {
        right: -9px;
        left: auto;
        top: 45px;
    }
    .user-container-top-nav .arrow-up {
        position: fixed;
        margin-left: 288px;
    }
    .user-container-top-nav .arrow-up:before {
        left: 0px;
    }
    .table-cell-mobile {
        display: table-cell;
    }
    .navbar-default {
        margin: 0 auto;
        padding: 4px 0;
    }
    .navbar-btn-container {
        border: none;
        padding: 0;
        z-index: 3;
        background: transparent;
        padding: 10px 0 10px 4px;
        cursor: pointer;
        line-height: 0;
        display: block;
    }
    .navbar-toggle div {
        width: 22px;
        height: 16px;
        position: relative;
        cursor: pointer;
        z-index: 3;
        display: block;
    }
    .navbar-toggle .icon-bar:nth-child(1) {
        top: 0;
    }
    .navbar-toggle div .icon-bar {
        width: 90%;
    }
    .navbar-toggle div .icon-bar {
        border-radius: 12px;
        height: 2px;
    }
    .navbar-toggle .icon-bar {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #fff;
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }
    .navbar-toggle div .icon-bar:nth-child(1) {
        width: 100%;
    }
    .navbar-toggle .icon-bar:nth-child(2) {
        top: 7px;
        width: 72%;
    }
    .navbar-toggle div .icon-bar:nth-child(3) {
        top: 14px;
        width: 100%;
    }
    .navbar-toggle:not(.collapsed) .icon-bar:nth-child(1) {
        top: 7px;
        transform: rotate(0);
    }
    .navbar-toggle:not(.collapsed) .icon-bar:nth-child(2) {
        top: 3px;
        width: 35%;
        transform: rotate(-40deg);
        left: 1px;
        opacity: 1;
    }
    .navbar-toggle:not(.collapsed) .icon-bar:nth-child(3) {
        top: 11px;
        transform: rotate(40deg);
        left: 1px;
        width: 35%;
    }
    button:focus {
        outline: none;
    }

    .footer-nav {
      border-bottom: none;
    }

    .login-mobile {
        display: block;
    }

    .header-main {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .content-wrapper {
        margin-top: 72px;
        padding-top: 53px;
    }

    .carousel-slider {
        margin-bottom: 12px !important;
    }

    .game-categories-wrapper {
       margin-right: 24px;
    }

        .mobile-search {
        display: block;
    }
    .header-container.active input {
        opacity: 1;
        height: 36px;
        background-color: rgb(255, 255, 255);
        border-color: rgb(225, 225, 225);
    }
    .input-search-mobile {
        display: block;
        width: calc(100% - 29px);
        margin-left: 29px;
        margin-top: 10px;
        height: 30px;
        background-color: rgba(29, 29, 28, .6);
        border: 1px solid #c6c6c6;
        padding: 1px 10px 1px 10px;
    }
    .casino-search {
        left: 44px;
        top: 43px;
    }
    .content-padding {
        padding-left: 15px;
        padding-right: 15px;
    }

    .logo {
       margin: 0;
    }

    .mobile-home ul.nav {
        width: 100%;
        height: 100vh;
        display: table;
        overflow: hidden;
        margin: 0;
        -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .2);
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .2);
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 120px;
    }
    .static-pages-content-wrapper .nav {
        height: 100% !important;
        padding-bottom: 20px !important;
        padding-top: 17px;
    }
    .mobile-home ul.nav>li {
        height: auto;
        margin: 0;
    }
    .mobile-home ul.nav>li>a {
        padding: 11px 5px;
    }
    .game_search_text_input {
       width: auto;
    }

        .desktop {
    display: none;
    }
    .desktop-header {
        display: none;
    }
    .mobile-logged {
        width: 100%;
    }
    .mobile-logged .navbar-brand {
        margin: 0;
    }
    .mobile-logged .user-toggle {
        right: 0;
        position: relative;
        margin-top: 0;
    }
    .mobile-logged .fa-user-circle:hover {
        color: #3797ef;
    }
    .user-logged {
        position: relative;
        margin-top: 1px;
        padding: 4px 0 4px 0;
        text-align: left;
        width: 100%;
    }
    .mobile-header {
        display: table;
        width: 100%;
        font-weight: bold;
        margin-top: -6px;
        background-color: #000;
        padding: 0px 15px 0px 15px;
    }
}

@media screen and (max-width: 1024px) and (min-width:991px) {
    .table {
        border-top: none;
    }

     .margin-0 {
        margin-top: 0 !important;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .grid--1of5 .grid-cell {
        -webkit-box-flex: 0 0 25%;
        -moz-box-flex: 0 0 25%;
        -webkit-flex: 0 0 25%;
        -moz-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
    .mobile-header>span, .mobile-header .refresh-balance-holder {
        font-size: 16px;
    }
}

@media screen and (max-width: 990px) {
    .only-desktop {
        display: none;
    }
    .account-messaging .tab-nav {
        min-height: 78px;
        border-bottom: 1px solid #d2d2d2;
    }
    .account-messaging .button-row {
        width: 33.33333333%;
        padding: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -ms-flex;
        display: flex;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        height: 66px;
    }
    .only-mobile {
        display: block !important;
    }
    .account-messaging .message-icon::before {
        width: 30px;
    }
    .account-messaging .mobile-message-nav-padding {
        padding-top: 6px;
        line-height: 1;
    }
    .account-content-border-top {
        border-top: 1px solid #d2d2d2;
    }
    .account-messaging .tab-nav ul li {
        width: 50%;
        line-height: inherit;
        border-bottom: none;
    }
    .account-messaging .tab-nav ul .inbox, .account-messaging .tab-nav ul .sent {
        border-left: 1px solid #d2d2d2;
    }
    .account-messaging .tab-nav ul li a {
        padding: 12px 5px;
        text-align: center;
    }
    .account-messaging .tab-nav ul li a span i, .icon-size-30 {
        font-size: 30px;
    }
    .account-messaging .unread-count {
        padding: 1px 6px 1px;
        min-width: 22px;
        font-size: 11px;
    }
    .account-messaging .tab-nav ul li.unread {
        display: none;
    }
    .account-messaging .tab-nav ul {
        width: 66.6667%;
        display: flex;
        border-top: none;
        float: right;
        margin-top: -66px;
    }
    .btn-message-mobile {
        background-color: transparent !important;
        padding: 12px 5px;
        width: 100%;
        text-align: center;
        line-height: inherit;
        color: rgb(78, 80, 80) !important;
        font-size: 14px;
        border-radius: 0px;
        border: none;
        font-weight: bold;
        margin-top: 10px;
    }
    .account-messaging .tab-nav ul li.active {
        border-left: 1px solid #d2d2d2;
        height: 78px;
        border-bottom: 1px solid #d2d2d2;
    }
    .account-messaging .tab-content {
        padding: 1em;
    }

   .contact-form-change-order .order {
        order: 2;
    }
    .contact-form-change-order .order-control {
        order: 1;
    }

    .table {
        border-top: 1px solid #3797ef !important;
    }
    .footer h6 {
        text-transform: uppercase;
    }
    .margin-footer {
        margin-bottom: 40px;
    }
}

@media screen and (min-width: 901px) {
    .footer-nav {
        padding-bottom: 5px;
    }
    .curacao-logo {
        width: 93px;
    }
}


@media screen and (max-width: 768px) {
    .game-categories {
        padding-top: 1px;
        min-height: 34px;
        min-width: 34px;
    }
    body .category-select>[class*='-control'], body .category-select>div, .game-categories>div>div {
        padding-top: 2px;
    }
    .curacao-logo {
        margin-left: 10px;
    }
    .live-casino .game-categories {
        width: 100%;
        background-color: #000;
    }
    /*
        .name, .play-for-real-game {
            display: none;
        }*/
    .no-mobile-footer {
        display: none;
    }
    .mobile-link {
        display: block
    }
    .footers-container-second .footer .footer-row .mobile-first-row {
        width: 100%;
    }
    .col-e-xs-6 {
        width: 50%;
    }
    .col-e-xs-12 {
        width: 100%;
    }
    .social-icons {
        padding-bottom: 20px;
        margin: auto;
        top: 15px;
        text-align: center;
    }
    .logos {
        display: block;
    }
    .footer-disclaimer p {
        font-size: 10px;
    }
    .col-e-sm-25 {
        width: 25%;
    }
    .col-e-sm-75 {
        width: 75%
    }
    .col-e-xs-25 {
        width: 16.6%;
    }
    .col-e-xs-75 {
        width: 83.3%;
    }
    .only-mobile {
        display: block;
    }
    .logo-header img {
        margin-top: 6px;
    }
     .no-mobile-768 {
        display: none !important;
    }
    .input-info .info-container {
        right: 32.1em;
        left: auto;
        border: none;
        background: transparent;
        top: 35px;
    }
    .input-info .arrow {
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid white;
        top: 0;
        right: 80%;
        top: 14px;
        right: 72%;
    }
    .input-info .info-container .info {
        border: solid 1px #c8c8c8;
    }
    .input-info .arrow:after {
        display: none;
    }

    .account-content-title-holder {
       padding: 12px 10px 13px;
       background-color: #3797ef;
    }
    .account-content-title {
       color: white;
       font-size: 16px;
       font-weight: 500;
    }
     .license-logo img {
        height: 30px;
    }
    .license-logo {
        padding-top: 5px;
    }
    .mobile-search {
        max-width: 403px;
    }
       .help-nav.main li {
        width: 50%;
        margin: 5px 0%;
        text-align: left;
        font-size: 13px;
    }
    .help-nav.main button {
        width: 35px;
        height: 35px;
    }
    .help-nav.main button img {
        width: 200%;
        margin-left: -5px;
    }
    .help-icon {
        display: table-cell;
        vertical-align: middle;
        padding-bottom: 0;
        font-size: 10px;
    }
    .help-nav.main li div {
        display: inline-flex;
        vertical-align: middle;
        padding-left: 10px;
    }
    .platform-help h1 {
        font-size: 33px;
        padding-top: 0px;
    }
    .platform-help .search {
        margin-top: 24px;
        margin-bottom: 46px;
    }
    .help-search {
        height: 55px;
    }
    .account-content-title-holder {
        padding: 12px 10px 13px;
        background-color: #3797ef;
    }
    .account-content-title {
        color: white;
    }
    .user-area-container-security {
        padding: 11px 8px
    }
    .account-content-center {
        width: 100%;
    }
    .modal-xl .title {
        font-size: 22px;
    }
    .modal-xl .text {
        font-size: 14px;
    }
    .col-e-xs-12 {
        width: 100%;
    }   

    .profile-layout-wrapper {
        display: flex;
        flex-direction: column; 
        row-gap: 15px;
    }

    .navigation {
        width: 100%;
    }

    .account-content {
        width: 100%;
        max-width: 100%;
     }

    .balance-summary .active-provider-card {
     height: 80px;
    }
    .balance-summary .active-provider-card .paymont-provider-card {
     padding: 0;
     border: 0;
     background: white;
    }

    .balance-summary .account-ballance {
        background: #eee;
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        padding: 15px 20px 15px;
    }

    .modal-header-deposit .back-arrow {
     display: block;
     position: absolute;
     top: calc(50% - 12px);
     left: 10px;
     color: white;
     transform: rotate(180deg);
     width: 25px;
    }
    
    .payment-details-section {
        display: flex;
        flex-direction: column-reverse;
    }
    
    .payment-details-section .right-side {
        padding: 0;
        width: 100%;
        max-height: 88vh;
        min-height: 86vh;
    }

    .payment-details-section .right-side .payment-control {
        padding: 20px;
    }
    

    .payment-details-section .left-side {
        width: 100%;
    }

    .payment-details-section .left-side  .paymont-provider-card {
        position: relative;
        min-height: 80px;
    }

    .left-side  .paymont-provider-card .image-container img {
       margin: auto auto;
      width: unset;
      height: 70px;
    }


    .payment-details-section .left-side .paymont-provider-card .arrow-image { 
        display: block;
        position: absolute;
        left: 95%;
        width: 20px;
        top: calc(50% - 10px);
    }
}

@media (max-width: 768px) and (min-width: 580px) {
    .grid--1of5 .grid-cell {
        -webkit-box-flex: 0 0 33.33333333%;
        -moz-box-flex: 0 0 33.33333333%;
        -webkit-flex: 0 0 33.33333333%;
        -moz-flex: 0 0 33.33333333%;
        -ms-flex: 0 0 33.33333333%;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333333333%;
    }
}

@media (max-width: 768px) and (min-width: 450px) {
    .grid--1of5 .grid-50 {
        -webkit-box-flex: 0 0 50%;
        -moz-box-flex: 0 0 50%;
        -webkit-flex: 0 0 50%;
        -moz-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 766px) {
    .right-side .balance-summary {
        width: 100%;
    }
}

@media (max-width: 580px) {
    .grid--1of5 .grid-cell {
        -webkit-box-flex: 0 0 50%;
        -moz-box-flex: 0 0 50%;
        -webkit-flex: 0 0 50%;
        -moz-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .birthday-form select {
        width: 83px;
    }
    .address-form select {
        width: 120px;
    }
}

@media (max-width: 575px) {
    .padding-left-footer-mobile {
        padding-left: 28px;
    }
    .desktop-curacao-license {
        padding-left: 15px;
        padding-right: 15px;
    }
   .padding-input-transaction-mobile {
        padding-top: 18px;
    }
}

@media (max-width: 540px) {
    .game-categories>div {
        margin-right: 16px;
    }
}

@media screen and (max-width: 500px) {
    .mobile-home ul.nav>li>a {
        padding: 7px 18px;
    }
    .platform-help h1 {
        font-size: 24px;
        padding: 0;
        margin: 0;
        text-align: left;
    }
}

@media screen and (min-width: 450px) {
    .account-content-turnon .btn {
        min-width: 180px;
        margin-left: 5px;
    }
}

@media screen and (max-width: 400px) {
    .curacao-logo-area img {
        width: 60px;
    }
    .game_search_text_input {
        width: 100%;
    }
}

@media screen and (max-width: 335px) {
    .birthday-form select {
        width: 62px;
    }
    .address-form select {
        width: 85px;
    }
}

